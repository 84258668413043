<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>客户联系</span>
      </div>
      <div style="margin: 20px"></div>
      <div class="list">
        <el-form label-width="130px" size="mini">
          <el-form-item label="联系电话" :required="true">
            <el-input size="medium" v-model="contact.phone"></el-input>
          </el-form-item>
          <el-form-item label="QQ号" :required="true">
            <el-input size="medium" v-model="contact.qq"></el-input>
          </el-form-item>
        </el-form>
        <div style="font-size: 14px; color: #999">提示：续费的时候，让客户怎么联系你的方式</div>
      </div>
    </div>

    <el-button type="primary" style="margin-left: 120px; width: 200px" @click="submit">保存</el-button>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  data() {
    return {
      contact: {
        phone: '',
        qq: ''
      }
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            if (res.contact) this.contact = res.contact
          }
        })
    },
    submit() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSystem',
          contact: this.contact
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    ::v-deep .el-input--medium {
      width: 300px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
